import { CascaderOption, SelectField, SelectFieldProps } from "../form";
import { Cascader } from "antd";
import { CascaderProps } from "antd/lib/cascader";
import {
  useWarehouses,
  useWarehouseOptions,
  useCurrentUser,
  useStockWarehouseOptions,
} from "../../lib/hooks";
import {
  WarehouseFragment,
  VariantShortFragment,
  StockFragment,
  UnitShortFragment,
} from "../../lib/graphql";
import { FormattedMessage } from "react-intl";
import { formatStock } from "../../lib/formats";
import { useEffectOnce } from "react-use";

type Props = Omit<CascaderProps<any>, "onChange"> & SelectFieldProps;

interface WarehouseSelectProps extends Omit<Props, "options"> {
  defaultValue?: string[];
  disabled?: boolean;
  skipLocalityScope?: boolean;
  onChangeWarehouse?(warehouse: WarehouseFragment): void;
}

export function WarehouseSelectAll({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  value,
  disabled,
  defaultValue,
  onChangeWarehouse,
  onChange,
  skipLocalityScope,
  ...restProps
}: WarehouseSelectProps) {
  const { items } = useWarehouses({
    variables: { filter: { skipLocalityScope } },
  });

  const options = items
    ? items.reduce((opts: CascaderOption<WarehouseFragment>[], warehouse) => {
        let option = opts.find((o) => o.value === warehouse.locality.id);
        if (!option) {
          option = {
            label: warehouse.locality.name,
            value: warehouse.locality.id,
            isLeaf: false,
            children: [],
          };
          opts.push(option);
        }

        if (option.children)
          option.children.push({
            label: warehouse.name,
            value: warehouse.id,
            item: warehouse,
          });
        return opts;
      }, [])
    : [];

  return (
    <Cascader
      options={options}
      style={{ width: "100%" }}
      defaultValue={defaultValue}
      onChange={(
        value: any,
        options: CascaderOption<WarehouseFragment>[] | any
      ) => {
        // has problems with value; do not use
        if (!options || !value || options.length < 2) return;

        if (onChange) onChange(value[1], options);

        const warehouseOption = options[1];

        if (!warehouseOption.item) return;

        // Check if warehouse selected
        if (onChangeWarehouse) {
          onChangeWarehouse(warehouseOption.item);
        }
      }}
      disabled={disabled}
      {...restProps}
    />
  );
}

export function WarehouseSelect({
  disabled,
  onChangeWarehouse,
  onChange,
  allLocalities,
  ...restProps
}: WarehouseSelectProps & { allLocalities?: boolean }) {
  const { currentLocalityIds: localityId } = useCurrentUser();

  return (
    <SelectField
      disabled={disabled}
      optionsHook={useWarehouseOptions}
      optionsHookParams={{
        variables: { filter: allLocalities ? undefined : { localityId } },
      }}
      placeholder={
        <FormattedMessage id="select.warehouse" defaultMessage="warehouse" />
      }
      showGroups={true}
      onChange={(warehouseId, opt) => {
        if (onChange) onChange(warehouseId, opt);

        if (onChangeWarehouse && opt) {
          onChangeWarehouse(opt.warehouse);
        }
      }}
      {...restProps}
    />
  );
}

interface StockWarehouseSelectProps extends SelectFieldProps {
  variant: VariantShortFragment;
  autoSelect?: boolean;
  unit?: UnitShortFragment;
  localityId?: string;
  defaultWarehouseId?: string;
  date?: any;
  onChangeWarehouse?(
    warehouse?: WarehouseFragment & { stock: Pick<StockFragment, "onHand"> }
  ): void;
}

export function StockWarehouseSelect({
  variant,
  localityId,
  unit,
  date,
  autoSelect = true,
  defaultWarehouseId,
  onChange,
  onChangeWarehouse,
  ...selectProps
}: StockWarehouseSelectProps) {
  const { currentTenant } = useCurrentUser();
  const showAllLocalities = !!currentTenant.multifarmInventoryEnabled;

  const { load, loading, options } = useStockWarehouseOptions(
    {
      variables: {
        variantId: variant.id,
        unitId: unit?.id,
        date: date,
        filter: {
          localityId:
            localityId && !showAllLocalities ? [localityId] : undefined,
        },
      },
    },
    showAllLocalities
  );

  useEffectOnce(() => {
    load()
      .then((result) => result.data?.warehouses?.items)
      .then((warehouses) => {
        const withStock = warehouses?.filter((w) =>
          defaultWarehouseId ? w.id == defaultWarehouseId : w.stock.onHand
        );
        if (withStock?.length) {
          const sorted = withStock.sort((w) =>
            w.locality.id == localityId ? -1 : 1
          );

          if (autoSelect && onChange) onChange(sorted[0].id, withStock);
          if (onChangeWarehouse) onChangeWarehouse(sorted[0]);
        }
      });
  });

  return (
    <SelectField
      style={{ width: "100%" }}
      placeholder={
        <FormattedMessage id="select.warehouse" defaultMessage="warehouse" />
      }
      loading={loading}
      options={options}
      showGroups={showAllLocalities}
      formatOption={(_, option) => (
        <span>
          {option.warehouse.name}
          <span style={{ marginLeft: "16px" }}>
            {formatStock(
              option.warehouse.stock.onHand,
              unit || variant.variationUnit,
              variant.variationUnit
            )}
          </span>
        </span>
      )}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => (
        <SelectField.DropdownHeader
          menu={menu}
          left={
            <FormattedMessage
              id="warehouses.entityName"
              defaultMessage="warehouse"
            />
          }
          right={
            <FormattedMessage
              id="variants.runningStock"
              defaultMessage="runningStock"
            />
          }
        />
      )}
      onChange={(
        val,
        options: ReturnType<typeof useStockWarehouseOptions>["options"]
      ) => {
        if (onChange) onChange(val, options);

        if (onChangeWarehouse) {
          const warehouse = options?.find((w) => w.key === val)?.warehouse;
          onChangeWarehouse(warehouse);
        }
      }}
      {...selectProps}
    />
  );
}
