import {
  useStockVariantOptions,
  useStockVariants,
} from "../../lib/hooks/inventory/variants";
import {
  ColumnsType,
  exportUrl,
  Space,
  TableInput,
  TableInputImportProps,
  TableInputProps,
} from "../shared";
import {
  StockVariantFragment,
  VirtualWarehouse,
  StockVariantsQueryVariables,
} from "../../lib/graphql";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactNode, useState } from "react";
import { variantSelectDropdown } from "../../lib/formats";
import { useUpdateEffect } from "react-use";
import { isNil, merge, uniq } from "lodash";
import { SelectField, buildNamePath, useFormContext } from "../form";
import { WithIDType, useItemCategoryOptions } from "../../lib/hooks";
import { FormInstance } from "antd";
import { ItemCategorySelect } from "../itemCategories";

export interface StockVariantTableInputProps<T>
  extends Omit<
    TableInputProps<T>,
    "entityById" | "tableSelectProps" | "importProps" | "columns"
  > {
  warehouseId?: string;
  variantFields?(variant: T): Record<string, any>;
  virtualWarehouse?: VirtualWarehouse;
  dropdownRightTitle?: ReactNode;
  optionsHookVariables?: StockVariantsQueryVariables;
  withStockOnly?: boolean;
  importProps?: TableInputImportProps;
  columns: ColumnsType<T> | ((variants: T[]) => ColumnsType<T>);
}

export function StockVariantTableInput<T extends StockVariantFragment>({
  warehouseId,
  variantFields,
  virtualWarehouse,
  dropdownRightTitle,
  optionsHookVariables,
  withStockOnly,
  importProps,
  columns,
  ...tableInputProps
}: StockVariantTableInputProps<T>) {
  const token =
    virtualWarehouse === VirtualWarehouse.Employees ? true : undefined;

  const filter: StockVariantsQueryVariables["filter"] = { token };
  if (withStockOnly) filter.warehouseId = warehouseId;

  const optionsHookParams = {
    variables: merge(
      {},
      {
        locationId: warehouseId,
        virtual: virtualWarehouse,
        filter,
      },
      optionsHookVariables
    ),
  };

  const { form } = useFormContext();

  const [refreshing, setRefreshing] = useState(false);
  const refresh = useStockVariants();

  const loadStockVariants = async ({
    queryOptions,
  }: {
    queryOptions: StockVariantsQueryVariables;
  }) => {
    const refreshParams = merge({}, queryOptions, optionsHookParams.variables);

    const r = await refresh(refreshParams);

    return r.data?.variants?.items as T[];
  };

  useUpdateEffect(() => {
    const source = form.getFieldValue(tableInputProps.name) as WithIDType[];
    if (source.length == 0) return;

    const id = source.map((v) => v.id);

    setRefreshing(true);

    const refreshParams = { queryOptions: { filter: { id } } };

    loadStockVariants(refreshParams).then((variants) => {
      if (variants) {
        const source = form.getFieldValue(tableInputProps.name) as WithIDType[];

        variants.forEach((v) => {
          const index = source.findIndex((s) => s.id == v.id);
          if (index >= 0) {
            form.setFields([
              {
                name: buildNamePath(tableInputProps.name, index, "stock"),
                value: v.stock,
              },
              {
                name: buildNamePath(tableInputProps.name, index, "cost"),
                value: v.cost,
              },
            ]);
          }
        });

        form.validateFields();
      }
    });

    setRefreshing(false);
  }, [optionsHookVariables?.date]);

  const tableImportProps = useStockVariantTableImportProps({
    importProps,
    form,
    variables: optionsHookParams.variables,
    loadStockVariants,
    setRefreshing,
  });

  const [selectorConfig, setSelectorConfig] = useState<"item" | "category">(
    "item"
  );

  const searchByItems = selectorConfig !== "category";
  return (
    <Space vertical>
      <TableInput
        allowBulkRemove
        tableSelectProps={{
          mode: "multiple",
          optionsHook: searchByItems
            ? useStockVariantOptions
            : useItemCategoryOptions,
          render: searchByItems
            ? undefined
            : ({ selectedItems, onChange }) => {
                return (
                  <ItemCategorySelect
                    multiple
                    placeholder={
                      <FormattedMessage
                        id="select.variants.byCategory"
                        defaultMessage="Find by item category"
                      />
                    }
                    value={selectedItems?.map((v: any) => v.key)}
                    onChange={(values, opts) => {
                      onChange(
                        values.map((v: any) => ({ key: v })),
                        opts
                      );
                    }}
                    style={{ width: "100%" }}
                  />
                );
              },
          optionsHookParams: searchByItems ? optionsHookParams : undefined,
          placeholder: (
            <FormattedMessage id="select.variants" defaultMessage="variants" />
          ),
          entityById: (_, { variant, loader, itemCategory }) => {
            if (variant) {
              const fields = variantFields && variantFields(variant);
              return { ...variant, quantity: undefined, ...fields };
            }

            if (loader) {
              return loader().then((variants: T[]) =>
                variants.map((variant) => {
                  const fields = variantFields && variantFields(variant);
                  return { ...variant, quantity: undefined, ...fields };
                })
              );
            }

            if (itemCategory) {
              const refreshParams = {
                queryOptions: {
                  filter: { itemCategoryId: [itemCategory.id] },
                  pageSize: 10000,
                },
              };

              return loadStockVariants(refreshParams).then((variants) =>
                variants
                  ? variants.map((variant) => {
                      const fields = variantFields && variantFields(variant);
                      return { ...variant, quantity: undefined, ...fields };
                    })
                  : []
              );
            }
          },
          dropdownRender: searchByItems
            ? variantSelectDropdown({ right: dropdownRightTitle })
            : undefined,

          prefix: ({ setSelectedItems }) => (
            <SelectField
              showSearch={false}
              allowClear={false}
              style={{ minWidth: "200px" }}
              onChange={(value) => {
                setSelectorConfig(value);
                setSelectedItems([]);
              }}
              defaultValue={selectorConfig}
              options={[
                {
                  key: "item",
                  label: (
                    <FormattedMessage
                      id="select.variants.byItem"
                      defaultMessage="Search by item"
                    />
                  ),
                },
                {
                  key: "category",
                  label: (
                    <FormattedMessage
                      id="select.variants.byCategory"
                      defaultMessage="Find by item category"
                    />
                  ),
                },
              ]}
            />
          ),
        }}
        tableProps={{ loading: refreshing }}
        importProps={tableImportProps}
        columns={
          Array.isArray(columns)
            ? columns
            : columns(form.getFieldValue(tableInputProps.name))
        }
        {...tableInputProps}
      />
    </Space>
  );
}

function useStockVariantTableImportProps({
  importProps,
  variables,
  form,
  setRefreshing,
  loadStockVariants,
}: {
  importProps?: TableInputImportProps;
  variables: StockVariantsQueryVariables;
  form: FormInstance<StockVariantFragment>;
  setRefreshing: (refreshing: boolean) => void;
  loadStockVariants: (params: {
    queryOptions: StockVariantsQueryVariables;
  }) => Promise<StockVariantFragment[] | undefined>;
}) {
  const intl = useIntl();

  if (!importProps) return;

  const nameHeader = intl.formatMessage({ id: "name" });
  const packagingHeader = intl.formatMessage({ id: "items.packaging" });
  const uomHeader = intl.formatMessage({ id: "uom", defaultMessage: "U/M" });
  const internalIdHeader = intl.formatMessage({ id: "internalId" });

  const variationKey = (d: any, v: StockVariantFragment) => {
    return (
      d[nameHeader] === v.name &&
      (!d[internalIdHeader] ||
        d[internalIdHeader].toString() === v.internalId) &&
      d[packagingHeader] === v.variationValue &&
      d[uomHeader] === v.variationUnit.abbr
    );
  };

  const importFromFile = async (data: any[]) => {
    setRefreshing(true);

    const variants = await loadStockVariants({
      queryOptions: {
        filter: { names: uniq(data.map((d) => d[nameHeader])) },
        pageSize: 10000,
      },
    });

    if (!variants) {
      setRefreshing(false);
      return;
    }

    const formVariants = form.getFieldValue("variants");

    // 1. select all variants that are not in the file
    // 2. join variants that are in the file
    const newVariants = formVariants
      .filter(
        (v: StockVariantFragment) => !data.some((d) => variationKey(d, v))
      )
      .concat(
        data
          .map((d) => {
            const formVariant = formVariants.find((v: StockVariantFragment) =>
              variationKey(d, v)
            );

            const allEditableFieldsEmpty = Object.values(importProps.headers)
              .filter((h) => h.editable)
              .every(({ label }) => isNil(d[label]) || d[label] === "");

            // skip if all editable fields are empty
            // or if filter is defined and does not match
            if (
              allEditableFieldsEmpty ||
              (importProps.filter && !importProps.filter(d))
            )
              return formVariant;

            const v = variants.find((v) => variationKey(d, v));

            if (!v) return formVariant;

            return {
              ...(formVariant || v),
              _destroy: undefined,
              ...Object.entries(importProps.headers)
                .filter(([, h]) => h.editable)
                .reduce((res, [k, v]) => {
                  res[k] = v.transformer
                    ? v.transformer(d[v.label])
                    : d[v.label];
                  return res;
                }, {} as Record<string, any>),
            };
          })
          .filter(Boolean)
      );

    form.setFields([
      {
        name: "variants",
        value: newVariants,
      },
    ]);

    form.validateFields();

    setRefreshing(false);
  };

  return {
    tooltip: importProps.tooltip,
    templateUrl: exportUrl(
      {
        export: "Inventory::Templates::StockVariants",
        ...Object.entries(variables).reduce((res, [k, v]) => {
          if (v) res[k] = JSON.stringify(v);
          return res;
        }, {} as Record<string, any>),
        // use same headers in backend as in frontend
        headers: {
          name: nameHeader,
          packaging: packagingHeader,
          uom: uomHeader,
          internalId: internalIdHeader,
          ...Object.entries(importProps.headers).reduce((res, [k, h]) => {
            res[k] = h.label;
            return res;
          }, {} as Record<string, any>),
        },
      },
      "xlsx"
    ),
    headers: importProps.headers,
    onUpload: importFromFile,
  };
}
