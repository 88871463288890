import { ItemCategoryOptionFragment } from "../../lib/graphql";
import { useItemCategoryOptions } from "../../lib/hooks";
import { TreeSelectField } from "../form";
import { TreeSelectFieldProps } from "../form/TreeSelectField";

interface ItemCategorySelectProps
  extends Partial<TreeSelectFieldProps<ItemCategoryOptionFragment>> {
  queryHook?: TreeSelectFieldProps<ItemCategoryOptionFragment>["queryHook"];
}

export function ItemCategorySelect({
  queryHook,
  ...props
}: ItemCategorySelectProps) {
  return (
    <TreeSelectField
      queryHook={queryHook || useItemCategoryOptions}
      queryHookParams={(parentCategoryId) => ({
        variables: {
          filter: { parentCategoryId },
        },
      })}
      buildTreeNode={(item) => ({
        title: item.name,
        pId: item.parentCategory && item.parentCategory.id,
        isLeaf: !item.subcategories || item.subcategories.length === 0,
        itemCategory: item,
      })}
      {...props}
    />
  );
}
